import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Section from "../components/Section"
import { Container } from "../components/Grid"

const NotFoundPage = () => (
  <Layout>
    <Section className="page-not-found">
      <Container>
        <SEO title="404: Page Not Found" />
        <div className="page-not-found">
          <h1>PAGE NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist.</p>
        </div>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
